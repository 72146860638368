/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { HostBinding, Input, Component, ElementRef, Renderer2, isDevMode, NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

const sizeClasses = {
    'default': 'k-icon',
    'xsmall': 'k-icon-xs',
    'small': 'k-icon-sm',
    'medium': 'k-icon-md',
    'large': 'k-icon-lg',
    'xlarge': 'k-icon-xl'
};
/**
 * @hidden
 */
class IconBase {
    constructor(element, renderer) {
        this.element = element;
        this.renderer = renderer;
    }
    get horizontalFlip() {
        return this.flip === 'horizontal' || this.flip === 'both';
    }
    get verticalFlip() {
        return this.flip === 'vertical' || this.flip === 'both';
    }
    /**
     * Specifies the theme color for the Icon.
     *
     * The possible values are:
     * * `inherit` (Default)&mdash;Applies coloring based on the current color.
     * * `primary` &mdash;Applies coloring based on primary theme color.
     * * `secondary`&mdash;Applies coloring based on secondary theme color.
     * * `tertiary`&mdash; Applies coloring based on tertiary theme color.
     * * `info`&mdash;Applies coloring based on info theme color.
     * * `success`&mdash; Applies coloring based on success theme color.
     * * `warning`&mdash; Applies coloring based on warning theme color.
     * * `error`&mdash; Applies coloring based on error theme color.
     * * `dark`&mdash; Applies coloring based on dark theme color.
     * * `light`&mdash; Applies coloring based on light theme color.
     * * `inverse`&mdash; Applies coloring based on inverse theme color.
     *
     */
    set themeColor(themeColor) {
        const element = this.element.nativeElement;
        this._themeColor = themeColor;
        this.renderer.removeClass(element, `k-color-${themeColor}`);
        if (themeColor) {
            this.renderer.addClass(element, `k-color-${themeColor}`);
        }
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * Specifies the size of the Icon.
     *
     * The possible values are:
     * * `default` (Default) (Font-size: 16px; Width: 16px; Height: 16px)
     * * `xsmall` (Font-size: 8px; Width: 8px; Height: 8px;)
     * * `small` (Font-size: 12px; Width: 12px; Height: 12px;)
     * * `medium` (Font-size: 32px; Width: 32px; Height: 32px;)
     * * `large` (Font-size: 48px; Width: 48px; Height: 48px;)
     * * `xlarge` (Font-size: 64px; Width: 64px; Height: 64px;)
     *
     */
    set size(size) {
        const element = this.element.nativeElement;
        this._size = size;
        const sizeClass = sizeClasses[this.size];
        this.renderer.removeClass(element, sizeClass);
        if (size !== 'default') {
            this.renderer.addClass(element, sizeClass);
        }
    }
    get size() {
        return this._size;
    }
}
IconBase.propDecorators = {
    horizontalFlip: [{ type: HostBinding, args: ['class.k-flip-h',] }],
    verticalFlip: [{ type: HostBinding, args: ['class.k-flip-v',] }],
    flip: [{ type: Input }],
    themeColor: [{ type: Input }],
    size: [{ type: Input }]
};

/**
 * Represents the Kendo UI Icon component for Angular.
 */
class IconComponent extends IconBase {
    constructor(element, renderer) {
        super(element, renderer);
        this.element = element;
        this.renderer = renderer;
        this.hostClass = true;
    }
    /**
     * Defines the name for an existing icon in a Kendo UI theme, which will be rendered.
     * All [Kendo UI Icons]({% slug icons %}#toc-list-of-font-icons) are supported.
     */
    set name(name) {
        const element = this.element.nativeElement;
        this._name = name;
        this.renderer.removeClass(element, `k-i-${this.name}`);
        if (name) {
            this.renderer.addClass(element, `k-i-${this.name}`);
        }
    }
    get name() {
        return this._name;
    }
}
IconComponent.decorators = [
    { type: Component, args: [{
                exportAs: 'kendoIcon',
                selector: 'kendo-icon',
                template: ``
            },] },
];
/** @nocollapse */
IconComponent.ctorParameters = () => [
    { type: ElementRef },
    { type: Renderer2 }
];
IconComponent.propDecorators = {
    hostClass: [{ type: HostBinding, args: ['class.k-icon',] }],
    name: [{ type: Input }]
};

/**
 * Represents the Kendo UI SVG Icon component for Angular.
 */
class SVGIconComponent extends IconBase {
    constructor(domSanitizer, element, renderer) {
        super(element, renderer);
        this.domSanitizer = domSanitizer;
        this.element = element;
        this.renderer = renderer;
        this.hostClass = true;
    }
    /**
     * Defines the SVG icon, which will be rendered.
     */
    set icon(icon) {
        const element = this.element.nativeElement;
        if (this._icon && this._icon.name) {
            this.renderer.removeClass(element, `k-svg-i-${this._icon.name}`);
        }
        this._icon = icon;
        this.renderer.addClass(element, `k-svg-i-${this._icon.name}`);
    }
    get icon() {
        return this._icon;
    }
    get content() {
        if (!this.icon) {
            return;
        }
        this.safeContent = this.safeContent || this.domSanitizer.bypassSecurityTrustHtml(this.icon.content);
        return this.safeContent;
    }
    ngOnInit() {
        this.verifyIconProperty();
    }
    verifyIconProperty() {
        if (!isDevMode()) {
            return;
        }
        if (!this._icon) {
            throw new Error(`
                Invalid configuration.
                The input [icon] is required for the Kendo UI SVG Icon component for Angular.
                See https://www.telerik.com/kendo-angular-ui/components/icons/svg-icon
            `);
        }
    }
}
SVGIconComponent.decorators = [
    { type: Component, args: [{
                exportAs: 'kendoSVGIcon',
                selector: 'kendo-svg-icon',
                template: `
        <svg xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        [attr.viewBox]="icon.viewBox" [innerHTML]="content">
        </svg>`
            },] },
];
/** @nocollapse */
SVGIconComponent.ctorParameters = () => [
    { type: DomSanitizer },
    { type: ElementRef },
    { type: Renderer2 }
];
SVGIconComponent.propDecorators = {
    hostClass: [{ type: HostBinding, args: ['class.k-svg-icon',] }],
    icon: [{ type: Input }]
};

const exportedModules = [
    IconComponent
];
const declarations = [
    ...exportedModules
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Icon component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Icon module
 * import { IconModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, IconModule], // import Icon module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class IconModule {
}
IconModule.decorators = [
    { type: NgModule, args: [{
                declarations: [declarations],
                exports: [exportedModules],
                imports: [CommonModule]
            },] },
];

const exportedModules$1 = [
    SVGIconComponent
];
const declarations$1 = [
    ...exportedModules$1
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the SVG Icon component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the SVG Icon module
 * import { SVGIconModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, SVGIconModule], // import SVG Icon module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class SVGIconModule {
}
SVGIconModule.decorators = [
    { type: NgModule, args: [{
                declarations: [declarations$1],
                exports: [exportedModules$1],
                imports: [CommonModule]
            },] },
];

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Icons components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Icons module
 * import { IconsModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, IconsModule], // import Icons module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class IconsModule {
}
IconsModule.decorators = [
    { type: NgModule, args: [{
                exports: [
                    IconModule,
                    SVGIconModule
                ]
            },] },
];

/**
 * Generated bundle index. Do not edit.
 */

export { IconBase, IconComponent, SVGIconComponent, IconModule, SVGIconModule, IconsModule };

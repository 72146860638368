/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { Input, Component, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostBinding, Directive, Optional, TemplateRef, NgZone, EventEmitter, ContentChild, Output, ElementRef, NgModule } from '@angular/core';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { Subject, Subscription } from 'rxjs';
import { DropDownListComponent, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { NumericTextBoxComponent, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { Keys, anyChanged, ResizeSensorComponent, EventsModule, ResizeSensorModule } from '@progress/kendo-angular-common';
import { validatePackage } from '@progress/kendo-licensing';
import { CommonModule } from '@angular/common';

/**
 * @hidden
 */
class PreventableEvent {
    constructor() {
        this.prevented = false;
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * If the event is prevented by any of its subscribers, returns `true`.
     *
     * @returns `true` if the default action was prevented. Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * Arguments for the `pageSizeChange` event. The `pageSizeChange` event fires when the page size
 * is changed from the UI. If you cancel the event, the change is prevented.
 */
class PageSizeChangeEvent extends PreventableEvent {
    /**
     * Constructs the event arguments for the `pageSizeChange` event.
     * @param newPageSize - The newly selected page size.
     * @hidden
     */
    constructor(newPageSize) {
        super();
        this.newPageSize = newPageSize;
    }
}

/**
 * @hidden
 */
class Messages extends ComponentMessages {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "firstPage", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "lastPage", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "previousPage", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "nextPage", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "page", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "pageNumberInputTitle", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "itemsPerPage", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "of", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "items", void 0);

var CustomMessagesComponent_1;
/**
 * Custom component messages override default component messages
 * ([see example]({% slug pager_globalization %}#toc-messages)).
 */
let CustomMessagesComponent = CustomMessagesComponent_1 = class CustomMessagesComponent extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
};
CustomMessagesComponent = CustomMessagesComponent_1 = __decorate([
    Component({
        providers: [
            {
                provide: Messages,
                useExisting: forwardRef(() => CustomMessagesComponent_1)
            }
        ],
        selector: 'kendo-datapager-messages',
        template: ``
    }),
    __metadata("design:paramtypes", [LocalizationService])
], CustomMessagesComponent);

/**
 * @hidden
 */
class PagerContextService {
    constructor() {
        this.changes = new Subject();
        this.pageChange = new Subject();
        this.pageSizeChange = new Subject();
    }
    get currentPage() {
        return this.skip / this.pageSize;
    }
    notifyChanges(changes) {
        this.total = changes.total;
        this.pageSize = changes.pageSize;
        this.skip = changes.skip;
        this.changes.next(changes);
    }
    changePage(page) {
        this.pageChange.next({ skip: page * this.pageSize, take: this.pageSize });
    }
    changePageSize(event) {
        this.pageSizeChange.next(event);
    }
    nextPage() {
        const nextPage = this.currentPage + 1;
        if (nextPage * this.pageSize <= this.total) {
            this.changePage(nextPage);
        }
    }
    prevPage() {
        const prevPage = this.currentPage - 1;
        if (prevPage * this.pageSize >= 0) {
            this.changePage(prevPage);
        }
    }
}

/**
 * @hidden
 */
class PagerElementComponent {
    constructor(localization, pagerContext, cd) {
        this.localization = localization;
        this.pagerContext = pagerContext;
        this.cd = cd;
        this.total = this.pagerContext.total;
        this.skip = this.pagerContext.skip;
        this.pageSize = this.pagerContext.pageSize;
    }
    /**
     * @hidden
     *
     * @readonly
     * @type {number}
     * @memberOf PagerElementComponent
     */
    get currentPage() {
        return Math.floor((this.skip || 0) / this.pageSize) + 1;
    }
    /**
     * @hidden
     *
     * @readonly
     * @type {number}
     * @memberOf PagerElementComponent
     */
    get totalPages() {
        return Math.ceil((this.total || 0) / this.pageSize);
    }
    /**
     * @hidden
     *
     * @param {string} key
     * @returns {string}
     *
     * @memberOf PagerElementComponent
     */
    textFor(key) {
        return this.localization.get(key);
    }
    /**
     * @hidden
     *
     * @param {number} page
     *
     * @memberOf PagerElementComponent
     */
    changePage(page) {
        this.pagerContext.changePage(page);
        return false;
    }
    /**
     * @hidden
     *
     * @memberOf PagerElementComponent
     */
    ngOnInit() {
        this.subscriptions = this.pagerContext.changes.subscribe(this.onChanges.bind(this));
        this.subscriptions.add(this.localization.changes.subscribe(() => this.cd.markForCheck()));
    }
    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
}

/**
 * Displays buttons for navigating to the first and to the previous page ([see example]({% slug pager_settings %})).
 */
let PagerPrevButtonsComponent = class PagerPrevButtonsComponent extends PagerElementComponent {
    constructor(localization, pagerContext, cd) {
        super(localization, pagerContext, cd);
    }
    /**
     * @hidden
     *
     * @readonly
     * @type {boolean}
     * @memberOf PagerPrevButtonsComponent
     */
    get disabled() {
        return this.currentPage === 1 || !this.total;
    }
    onChanges({ total, skip, pageSize }) {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }
};
PagerPrevButtonsComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'kendo-datapager-prev-buttons',
        template: `
        <button
            type="button"
            class="k-link k-pager-nav k-pager-first"
            [class.k-state-disabled]="disabled"
            [title]="textFor('firstPage')"
            [attr.aria-label]="textFor('firstPage')"
            [attr.aria-disabled]="disabled ? true : undefined"
            (click)="currentPage !== 1 ? changePage(0) : false">
            <span class="k-icon k-i-seek-w" [attr.aria-label]="textFor('firstPage')"></span>
        </button>
        <button
            type="button"
            class="k-link k-pager-nav"
            [class.k-state-disabled]="disabled"
            [title]="textFor('previousPage')"
            [attr.aria-label]="textFor('previousPage')"
            [attr.aria-disabled]="disabled ? true : undefined"
            (click)="currentPage !== 1 ? changePage(currentPage-2) : false">
            <span class="k-icon k-i-arrow-w" [attr.aria-label]="textFor('previousPage')"></span>
        </button>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        PagerContextService,
        ChangeDetectorRef])
], PagerPrevButtonsComponent);

/**
 * Displays a drop-down list for the page size selection ([see example]({% slug pager_settings %})).
 */
let PagerPageSizesComponent = class PagerPageSizesComponent extends PagerElementComponent {
    constructor(localization, cd, pagerContext) {
        super(localization, pagerContext, cd);
        this.pagerContext = pagerContext;
        this._pageSizes = [];
    }
    get pageSizes() {
        return this._pageSizes;
    }
    /**
     * The page sizes collection. Can contain numbers and [PageSizeItem]({% slug api_pager_pagesizeitem %}) objects.
     *
     * @example
     * {% meta height:400 %}
     * ```ts-preview
     * _@Component({
     *    selector: 'my-app',
     *    template: `
     *        <div *ngFor="let item of pagedData" style="border: 1px solid black; padding: 10px;">
     *            <span>{{item.ProductID}}. </span>
     *            <span>{{item.ProductName}}</span>
     *        </div>
     *        <kendo-datapager
     *             [skip]="skip"
     *             [pageSize]="pageSize"
     *             [total]="total"
     *             (pageChange)="onPageChange($event)">
     *           <ng-template kendoDataPagerTemplate>
     *               <kendo-datapager-page-sizes [pageSizes]="pagesizes"></kendo-datapager-page-sizes>
     *           </ng-template>
     *        </kendo-datapager>
     *    `
     * })
     * class AppComponent {
     *    public data: any[] = products;
     *    public pagedData = [];
     *    public skip = 0;
     *    public pageSize = 2;
     *    public total = products.length;
     *    public pagesizes = [{text: 'One', value: 1}, {text: 'Two', value: 2}, {text: 'All', value : 'all'}];
     *
     *    public ngOnInit() {
     *        this.pageData();
     *    }
     *
     *    public onPageChange(e) {
     *        this.skip = e.skip;
     *        this.pageSize = e.take;
     *        this.pageData();
     *    }
     *
     *    private pageData() {
     *        this.pagedData = this.data.slice(this.skip, this.skip + this.pageSize);
     *    }
     * }
     *
     * const products = [{
     *   'ProductID' : 1,
     *   'ProductName' : "Chai",
     *   'SupplierID' : 1,
     *   'CategoryID' : 1,
     *   'QuantityPerUnit' : "10 boxes x 20 bags",
     *   'UnitPrice' : 18.0000,
     *   'UnitsInStock' : 39,
     *   'UnitsOnOrder' : 0,
     *   'ReorderLevel' : 10,
     *   'Discontinued' : false
     *
     * }, {
     *   'ProductID' : 2,
     *   'ProductName' : "Chang",
     *   'SupplierID' : 1,
     *   'CategoryID' : 1,
     *   'QuantityPerUnit' : "24 - 12 oz bottles",
     *   'UnitPrice' : 19.0000,
     *   'UnitsInStock' : 17,
     *   'UnitsOnOrder' : 40,
     *   'ReorderLevel' : 25,
     *   'Discontinued' : false
     * }, {
     *   'ProductID' : 3,
     *   'ProductName' : "Aniseed Syrup",
     *   'SupplierID' : 1,
     *   'CategoryID' : 2,
     *   'QuantityPerUnit' : "12 - 550 ml bottles",
     *   'UnitPrice' : 10.0000,
     *   'UnitsInStock' : 13,
     *   'UnitsOnOrder' : 70,
     *   'ReorderLevel' : 25,
     *   'Discontinued' : false
     * }, {
     *   'ProductID' : 4,
     *   'ProductName' : "Chef Anton\'s Cajun Seasoning",
     *   'SupplierID' : 2,
     *  'CategoryID' : 2,
     *   'QuantityPerUnit' : "48 - 6 oz jars",
     *   'UnitPrice' : 22.0000,
     *   'UnitsInStock' : 53,
     *   'UnitsOnOrder' : 0,
     *   'ReorderLevel' : 0,
     *   'Discontinued' : false
     * }, {
     *   'ProductID' : 5,
     *   'ProductName' : "Chef Anton\'s Gumbo Mix",
     *   'SupplierID' : 2,
     *   'CategoryID' : 2,
     *   'QuantityPerUnit' : "36 boxes",
     *   'UnitPrice' : 21.3500,
     *   'UnitsInStock' : 0,
     *   'UnitsOnOrder' : 0,
     *   'ReorderLevel' : 0,
     *   'Discontinued' : true
     * }, {
     *   'ProductID' : 6,
     *   'ProductName' : "Grandma\'s Boysenberry Spread",
     *   'SupplierID' : 3,
     *   'CategoryID' : 2,
     *   'QuantityPerUnit' : "12 - 8 oz jars",
     *   'UnitPrice' : 25.0000,
     *   'UnitsInStock' : 120,
     *   'UnitsOnOrder' : 0,
     *   'ReorderLevel' : 25,
     *   'Discontinued' : false
     * }];
     * ```
     * {% endmeta %}
     */
    set pageSizes(pageSizes) {
        let normalizedItems = [];
        pageSizes.forEach(item => {
            if (typeof item === 'number') {
                normalizedItems.push({
                    text: item.toString(),
                    value: item
                });
            }
            else {
                normalizedItems.push(item);
            }
        });
        if (this.pageSize && !normalizedItems.some(item => item.value === this.pageSize)) {
            normalizedItems = [{ text: this.pageSize.toString(), value: this.pageSize }, ...normalizedItems];
        }
        this._pageSizes = normalizedItems;
    }
    /**
     * @hidden
     *
     * @readonly
     */
    get classes() {
        return true;
    }
    ngAfterViewInit() {
        this.dropDownList.wrapper.nativeElement.setAttribute('aria-label', this.textFor('itemsPerPage'));
    }
    /**
     * @hidden
     */
    pageSizeChange(value, dropdownlist) {
        const event = new PageSizeChangeEvent(value);
        this.pagerContext.changePageSize(event);
        if (event.isDefaultPrevented()) {
            dropdownlist.writeValue(this.pageSize);
        }
    }
    onChanges({ total, skip, pageSize }) {
        this.total = total;
        this.skip = skip;
        this.pageSize = typeof pageSize === 'number' ? pageSize : this.total;
        this.cd.markForCheck();
    }
};
__decorate([
    ViewChild('dropdownlist', { static: true }),
    __metadata("design:type", DropDownListComponent)
], PagerPageSizesComponent.prototype, "dropDownList", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], PagerPageSizesComponent.prototype, "pageSizes", null);
__decorate([
    HostBinding("class.k-pager-sizes"),
    HostBinding("class.k-label"),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PagerPageSizesComponent.prototype, "classes", null);
PagerPageSizesComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'kendo-datapager-page-sizes',
        template: `
        <kendo-dropdownlist
            #dropdownlist
            [data]="pageSizes"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [value]="pageSize"
            (valueChange)="pageSizeChange($event, dropdownlist)"></kendo-dropdownlist>
        {{ textFor('itemsPerPage') }}
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        ChangeDetectorRef,
        PagerContextService])
], PagerPageSizesComponent);

/**
 * Represents the pager template which helps to customize the pager appearance. To define a pager
 * template, nest an `<ng-template>` tag with the `kendoDataPagerTemplate` directive inside `<kendo-datapager>`.
 *
 * The template context provides the following fields:
 * * `currentPage`&mdash;The index of the displayed page.
 * * `pageSize`&mdash;The value of the current `pageSize`.
 * * `skip`&mdash;The current skip value.
 * * `total`&mdash;The total number of records.
 * * `totalPages`&mdash;The total number of available pages.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *       <kendo-datapager [total]="total" [pageSize]="pageSize" [skip]="skip" (pageChange)="onPageChange($event)">
 *           <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
 *               <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
 *               <kendo-datapager-numeric-buttons [buttonCount]="10"></kendo-datapager-numeric-buttons>
 *               <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
 *               <kendo-datapager-info></kendo-datapager-info>
 *               Current page: {{currentPage}}
 *           </ng-template>
 *        </kendo-datapager>
 *     `
 * })
 *
 * class AppComponent {
 *     public skip = 0;
 *     public pageSize = 10;
 *     public total = 100;
 *
 *     public onPageChange(e: any): void {
 *         this.skip = e.skip;
 *         this.pageSize = e.take;
 *     }
 * }
 *
 * ```
 */
let PagerTemplateDirective = class PagerTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
PagerTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDataPagerTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], PagerTemplateDirective);

/**
 * Displays numeric buttons to enable navigation between the pages.
 */
let PagerNumericButtonsComponent = class PagerNumericButtonsComponent extends PagerElementComponent {
    constructor(localization, cd, pagerContext) {
        super(localization, pagerContext, cd);
        this.pagerContext = pagerContext;
        this.numbersWrapClass = true;
    }
    /**
     * @hidden
     *
     * @readonly
     * @type {number[]}
     * @memberOf PagerNumericButtonsComponent
     */
    get buttons() {
        let result = [];
        for (let idx = this.start; idx <= this.end; idx++) {
            result.push(idx);
        }
        return result;
    }
    /**
     * @hidden
     */
    get end() {
        return Math.min((this.start + this.buttonCount) - 1, this.totalPages);
    }
    /**
     * @hidden
     */
    get start() {
        const page = this.currentPage;
        const buttonCount = this.buttonCount;
        if (page > buttonCount) {
            const reminder = (page % buttonCount);
            return (reminder === 0) ? (page - buttonCount) + 1 : (page - reminder) + 1;
        }
        return 1;
    }
    /**
     * @hidden
     */
    pageLabel(num) {
        const pageText = this.textFor('page');
        if (pageText) {
            return pageText + ' ' + num;
        }
        return num.toString();
    }
    /**
     * @hidden
     */
    onSelectChange(e) {
        const target = e.target;
        const valueAsNumber = Number(target.value);
        if (!Number.isNaN(valueAsNumber)) {
            this.changePage(valueAsNumber - 1);
        }
        else {
            if (target.value === 'previousButtons') {
                this.changePage(this.start - 2);
            }
            else {
                this.changePage(this.end);
            }
        }
    }
    onChanges({ total, skip, pageSize }) {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }
};
__decorate([
    HostBinding('class.k-pager-numbers-wrap'),
    __metadata("design:type", Boolean)
], PagerNumericButtonsComponent.prototype, "numbersWrapClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], PagerNumericButtonsComponent.prototype, "buttonCount", void 0);
PagerNumericButtonsComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'kendo-datapager-numeric-buttons',
        template: `
        <select
            class="k-dropdown"
            [value]="(currentPage).toString()"
            (change)="onSelectChange($event)">
            <option *ngIf="start > 1"
                class="k-link"
                value="previousButtons"
                [attr.aria-label]="pageLabel(start - 1)">...
            </option>
            <option *ngFor="let num of buttons"
                [value]="num.toString()"
                [attr.aria-label]="pageLabel(num)"
                [attr.aria-current]="currentPage == num ? 'page' : undefined"
                [ngClass]="{'k-link': true, 'k-state-selected':currentPage == num}">
                {{num}}
            </option>
            <option *ngIf="end < totalPages"
                value="nextButtons"
                class="k-link"
                [attr.aria-label]="pageLabel(end + 1)">...
            </option>
        </select>
        <ul [ngClass]="{'k-pager-numbers': true, 'k-reset': true}">
            <li *ngIf="start > 1">
                <button
                    type="button"
                    class="k-link"
                    [attr.aria-label]="pageLabel(start - 1)"
                    (click)="changePage(start - 2)">...</button>
            </li>
            <li *ngFor="let num of buttons">
                <button
                    type="button"
                    [attr.aria-label]="pageLabel(num)"
                    [attr.aria-current]="currentPage == num ? 'page' : undefined"
                    [ngClass]="{'k-link': true, 'k-state-selected':currentPage == num}"
                    (click)="changePage(num - 1)">
                    {{num}}
                </button>
            </li>
            <li *ngIf="end < totalPages">
                <button
                    type="button"
                    class="k-link"
                    [attr.aria-label]="pageLabel(end + 1)"
                    (click)="changePage(end)">...</button>
            </li>
        </ul>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        ChangeDetectorRef,
        PagerContextService])
], PagerNumericButtonsComponent);

/**
 * Displays buttons for navigating to the next and to the last page ([see example]({% slug pager_settings %})).
 */
let PagerNextButtonsComponent = class PagerNextButtonsComponent extends PagerElementComponent {
    constructor(localization, pagerContext, cd) {
        super(localization, pagerContext, cd);
    }
    /**
     * @hidden
     *
     * @readonly
     * @type {boolean}
     * @memberOf PagerNextButtonsComponent
     */
    get disabled() {
        return this.currentPage === this.totalPages || !this.total;
    }
    onChanges({ total, skip, pageSize }) {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }
};
PagerNextButtonsComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'kendo-datapager-next-buttons',
        template: `
        <button
            type="button"
            class="k-link k-pager-nav"
            [class.k-state-disabled]="disabled"
            [title]="textFor('nextPage')"
            [attr.aria-label]="textFor('nextPage')"
            [attr.aria-disabled]="disabled ? true : undefined"
            (click)="currentPage !== totalPages ? changePage(currentPage) : false">
            <span class="k-icon k-i-arrow-e" [attr.aria-label]="textFor('nextPage')"></span>
        </button>
        <button
            type="button"
            class="k-link k-pager-nav k-pager-last"
            [class.k-state-disabled]="disabled"
            [title]="textFor('lastPage')"
            [attr.aria-label]="textFor('lastPage')"
            [attr.aria-disabled]="disabled ? true : undefined"
            (click)="currentPage !== totalPages ? changePage(totalPages-1) : false">
            <span class="k-icon k-i-seek-e" [attr.aria-label]="textFor('lastPage')"></span>
        </button>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        PagerContextService,
        ChangeDetectorRef])
], PagerNextButtonsComponent);

/**
 * Displays an input element which allows the typing and rendering of page numbers.
 */
let PagerInputComponent = class PagerInputComponent extends PagerElementComponent {
    constructor(localization, pagerContext, zone, cd) {
        super(localization, pagerContext, cd);
        this.pagerContext = pagerContext;
        this.zone = zone;
        /**
         * @hidden
         *
         * @param {string} value
         *
         * @memberOf PagerInputComponent
         */
        this.handleKeyDown = (event) => {
            let incomingValue = this.numericInput.value || this.current;
            // tslint:disable:deprecation
            if (event.keyCode === Keys.Enter) {
                event.preventDefault();
                if (incomingValue !== this.current) {
                    this.zone.run(() => {
                        this.changePage(incomingValue - 1);
                    });
                }
            }
        };
        /**
         * @hidden
         *
         * @param {string} value
         *
         * @memberOf PagerInputComponent
         */
        this.handleBlur = () => {
            const inputValue = this.numericInput.value;
            if (!inputValue) {
                this.numericInput.writeValue(this.current);
                return;
            }
            if (inputValue !== this.current) {
                this.zone.run(() => {
                    this.changePage(inputValue - 1);
                });
            }
        };
    }
    /**
     * @hidden
     */
    get current() {
        return this.hasPages ? this.currentPage : 0;
    }
    /**
     * @hidden
     */
    get hasPages() {
        return this.totalPages !== 0;
    }
    onChanges({ total, skip, pageSize }) {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }
};
__decorate([
    ViewChild(NumericTextBoxComponent, { static: true }),
    __metadata("design:type", NumericTextBoxComponent)
], PagerInputComponent.prototype, "numericInput", void 0);
PagerInputComponent = __decorate([
    Component({
        selector: 'kendo-datapager-input',
        template: `
     <span [ngClass]="{'k-pager-input': true, 'k-label': true}">
        {{textFor('page')}}
        <kendo-numerictextbox
            [spinners]="false"
            [decimals]="0"
            format="n0"
            [disabled]="!hasPages"
            [value]="current"
            [min]="hasPages ? 1 : 0"
            [max]="totalPages"
            [autoCorrect]="true"
            [title]="textFor('pageNumberInputTitle')"
            [kendoEventsOutsideAngular]="{
                keydown: handleKeyDown,
                focusout: handleBlur
            }">
        </kendo-numerictextbox>
        {{textFor('of')}} {{totalPages}}
     </span>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        PagerContextService,
        NgZone,
        ChangeDetectorRef])
], PagerInputComponent);

/**
 * Displays information about the current page and the total number of records ([see example]({% slug pager_settings %})).
 */
let PagerInfoComponent = class PagerInfoComponent extends PagerElementComponent {
    constructor(localization, cd, pagerContext) {
        super(localization, pagerContext, cd);
        this.pagerContext = pagerContext;
    }
    /**
     * @hidden
     *
     * @readonly
     * @type {number}
     * @memberOf PagerInfoComponent
     */
    get maxItems() {
        return Math.min(this.currentPage * this.pageSize, this.total);
    }
    /**
     * @hidden
     *
     * @readonly
     * @type {number}
     * @memberOf PagerInfoComponent
     */
    get currentPageText() {
        return this.total ?
            (this.currentPage - 1) * this.pageSize + 1 :
            0;
    }
    /**
     * @hidden
     *
     * @readonly
     * @type {boolean}
     * @memberOf PagerInfoComponent
     */
    get classes() {
        return true;
    }
    onChanges({ total, skip, pageSize }) {
        this.total = total;
        this.skip = skip;
        this.pageSize = pageSize;
        this.cd.markForCheck();
    }
};
__decorate([
    HostBinding("class.k-pager-info"),
    HostBinding("class.k-label"),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PagerInfoComponent.prototype, "classes", null);
PagerInfoComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'kendo-datapager-info',
        template: `{{currentPageText}} - {{maxItems}} {{textFor('of')}} {{total}} {{textFor('items')}}`
    }),
    __metadata("design:paramtypes", [LocalizationService,
        ChangeDetectorRef,
        PagerContextService])
], PagerInfoComponent);

/**
 * @hidden
 */
const RESPONSIVE_BREAKPOINT_MEDIUM = 600;
/**
 * @hidden
 */
const RESPONSIVE_BREAKPOINT_LARGE = 768;

/**
 * @hidden
 */
const DEFAULT_PAGE_SIZE_VALUES = [5, 10, 20];

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-pager',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1620216405,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

let PagerComponent = class PagerComponent {
    constructor(pagerContext, element, localization) {
        this.pagerContext = pagerContext;
        this.element = element;
        this.localization = localization;
        /**
         * Represents the total number of data items in the collection.
         */
        this.total = 0;
        /**
         * Defines the number of data items to be skipped.
         */
        this.skip = 0;
        /**
         * Sets the maximum numeric buttons count before the buttons are collapsed.
         */
        this.buttonCount = 10;
        /**
         * Toggles the information about the current page and the total number of records.
         */
        this.info = true;
        /**
         * Defines the type of the pager.
         */
        this.type = 'numeric';
        /**
         * Toggles the **Previous** and **Next** buttons.
         */
        this.previousNext = true;
        /**
         * Fires when the current page of the Pager is changed ([see example]({% slug overview_pager %}#toc-basic-usage)).
         * You have to handle the event yourself and page the data.
         */
        this.pageChange = new EventEmitter();
        /**
         * Fires when the page size of the Pager is changed.
         * You have to handle the event yourself and page the data.
         * If the event is prevented, the page size will remain unchanged ([see example]({% slug pager_events %})).
         */
        this.pageSizeChange = new EventEmitter();
        this.pagerWrapClass = true;
        this.pagerClass = true;
        this.widgetClass = true;
        this.widgetRole = 'navigation';
        this.subscriptions = new Subscription();
        this._templateContext = {};
        this._pageSizeValues = DEFAULT_PAGE_SIZE_VALUES;
        this.resizeHandler = () => {
            if (this.template) {
                return;
            }
            const element = this.element.nativeElement;
            const width = element.offsetWidth;
            if (width < RESPONSIVE_BREAKPOINT_MEDIUM) {
                element.classList.remove('k-pager-md');
                element.classList.add('k-pager-sm');
            }
            else if (width >= RESPONSIVE_BREAKPOINT_MEDIUM && width < RESPONSIVE_BREAKPOINT_LARGE) {
                element.classList.add('k-pager-md');
                element.classList.remove('k-pager-sm');
            }
            else {
                element.classList.remove('k-pager-md');
                element.classList.remove('k-pager-sm');
            }
        };
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
    }
    /**
     * Shows a dropdown for selecting the page size.
     * When set to `true`, the dropdown will contain the default list of options - 5, 10, 20.
     * To customize the list of options, set `pageSizeValues` to an array of the desired values.
     * The array can contain numbers and [PageSizeItem]({% slug api_pager_pagesizeitem %}) objects.
     */
    set pageSizeValues(pageSizeValues) {
        this._pageSizeValues = Array.isArray(pageSizeValues) ? pageSizeValues : pageSizeValues ? DEFAULT_PAGE_SIZE_VALUES : false;
    }
    get dir() {
        return this.direction;
    }
    get totalPages() {
        return Math.ceil((this.total || 0) / this.pageSize);
    }
    get currentPage() {
        return Math.floor((this.skip || 0) / this.pageSize) + 1;
    }
    get pageSizeValues() {
        return this._pageSizeValues;
    }
    get templateContext() {
        const context = this._templateContext;
        context.totalPages = this.totalPages;
        context.total = this.total;
        context.skip = this.skip;
        context.pageSize = this.pageSize;
        context.currentPage = this.currentPage;
        return context;
    }
    ngOnInit() {
        this.subscriptions.add(this.pagerContext.pageChange.subscribe(this.changePage.bind(this)));
        this.subscriptions.add(this.pagerContext.pageSizeChange.subscribe(this.changePageSize.bind(this)));
        this.subscriptions.add(this.resizeSensor.resize.subscribe(() => this.resizeHandler()));
        this.subscriptions.add(this.localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
        }));
    }
    ngAfterViewInit() {
        this.resizeHandler();
    }
    ngOnChanges(changes) {
        if (anyChanged(["pageSize", "skip", "total"], changes, false)) {
            this.pagerContext.notifyChanges({
                pageSize: this.pageSize,
                skip: this.skip,
                total: this.total
            });
        }
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    /**
     * @hidden
     */
    changePage(event) {
        this.pageChange.emit(event);
    }
    /**
     * @hidden
     */
    changePageSize(event) {
        this.pageSizeChange.emit(event);
        if (!event.isDefaultPrevented()) {
            if (event.newPageSize === 'all') {
                this.pageChange.emit({ skip: 0, take: this.total });
            }
            else {
                this.pageChange.emit({ skip: 0, take: event.newPageSize });
            }
        }
    }
    /**
     * @hidden
     */
    onPageSizeChange(event) {
        this.pageSizeChange.emit(event);
        if (!event.isDefaultPrevented()) {
            this.pageChange.emit({ skip: this.skip, take: event.newPageSize });
        }
    }
};
__decorate([
    ViewChild(ResizeSensorComponent, { static: true }),
    __metadata("design:type", ResizeSensorComponent)
], PagerComponent.prototype, "resizeSensor", void 0);
__decorate([
    ContentChild(PagerTemplateDirective, { static: true }),
    __metadata("design:type", PagerTemplateDirective)
], PagerComponent.prototype, "template", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], PagerComponent.prototype, "total", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], PagerComponent.prototype, "skip", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], PagerComponent.prototype, "pageSize", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], PagerComponent.prototype, "buttonCount", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PagerComponent.prototype, "info", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PagerComponent.prototype, "type", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], PagerComponent.prototype, "pageSizeValues", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PagerComponent.prototype, "previousNext", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PagerComponent.prototype, "pageChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PagerComponent.prototype, "pageSizeChange", void 0);
__decorate([
    HostBinding('class.k-pager-wrap'),
    __metadata("design:type", Boolean)
], PagerComponent.prototype, "pagerWrapClass", void 0);
__decorate([
    HostBinding('class.k-pager'),
    __metadata("design:type", Boolean)
], PagerComponent.prototype, "pagerClass", void 0);
__decorate([
    HostBinding('class.k-widget'),
    __metadata("design:type", Boolean)
], PagerComponent.prototype, "widgetClass", void 0);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], PagerComponent.prototype, "widgetRole", void 0);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], PagerComponent.prototype, "dir", null);
PagerComponent = __decorate([
    Component({
        selector: 'kendo-datapager',
        exportAs: 'kendoDataPager',
        providers: [
            LocalizationService,
            PagerContextService
        ],
        template: `
        <ng-container kendoDataPagerLocalizedMessages
            i18n-firstPage="kendo.pager.firstPage|The label for the first page button in the Pager"
            firstPage="Go to the first page"

            i18n-previousPage="kendo.pager.previousPage|The label for the previous page button in the Pager"
            previousPage="Go to the previous page"

            i18n-nextPage="kendo.pager.nextPage|The label for the next page button in the Pager"
            nextPage="Go to the next page"

            i18n-lastPage="kendo.pager.lastPage|The label for the last page button in the Pager"
            lastPage="Go to the last page"

            i18n-page="kendo.pager.page|The label before the current page number in the Pager"
            page="Page"

            i18n-of="kendo.pager.of|The label before the total pages number in the Pager"
            of="of"

            i18n-pageNumberInputTitle="kendo.pager.pageNumberInputTitle|The label for the pager input in the Pager"
            pageNumberInputTitle="Page Number"

            i18n-items="kendo.pager.items|The label after the total pages number in the Pager"
            items="items"

            i18n-itemsPerPage="kendo.pager.itemsPerPage|The label for the page size chooser in the Pager"
            itemsPerPage="items per page"
         >
        </ng-container>
        <ng-container
            *ngIf="template?.templateRef"
            [ngTemplateOutlet]="template.templateRef"
            [ngTemplateOutletContext]="templateContext">
        </ng-container>
        <ng-container *ngIf="!template?.templateRef">
            <kendo-datapager-prev-buttons *ngIf="previousNext"></kendo-datapager-prev-buttons>
            <kendo-datapager-numeric-buttons
                *ngIf="type === 'numeric' && buttonCount > 0"
                [buttonCount]="buttonCount">
            </kendo-datapager-numeric-buttons>
            <kendo-datapager-input *ngIf="type === 'input'"></kendo-datapager-input>
            <kendo-datapager-next-buttons *ngIf="previousNext"></kendo-datapager-next-buttons>
            <kendo-datapager-info *ngIf='info'></kendo-datapager-info>
            <kendo-datapager-page-sizes *ngIf="pageSizeValues" [pageSizes]="pageSizeValues"></kendo-datapager-page-sizes>
        </ng-container>
        <kendo-resize-sensor></kendo-resize-sensor>
  `
    }),
    __metadata("design:paramtypes", [PagerContextService,
        ElementRef,
        LocalizationService])
], PagerComponent);

var LocalizedMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedMessagesDirective = LocalizedMessagesDirective_1 = class LocalizedMessagesDirective extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
};
LocalizedMessagesDirective = LocalizedMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: Messages,
                useExisting: forwardRef(() => LocalizedMessagesDirective_1)
            }
        ],
        selector: '[kendoDataPagerLocalizedMessages]'
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedMessagesDirective);

const importedModules = [
    CommonModule,
    NumericTextBoxModule,
    DropDownListModule,
    EventsModule,
    ResizeSensorModule
];
const INTERNAL_COMPONENTS = [
    PagerComponent,
    PagerPrevButtonsComponent,
    PagerNextButtonsComponent,
    PagerNumericButtonsComponent,
    PagerInputComponent,
    PagerInfoComponent,
    PagerPageSizesComponent,
    PagerTemplateDirective,
    LocalizedMessagesDirective,
    CustomMessagesComponent
];
const providers = [
    {
        provide: L10N_PREFIX,
        useValue: 'kendo.pager'
    }
];
/**
 * @hidden
 */
let PagerModule = class PagerModule {
    static exports() {
        return [
            PagerComponent,
            PagerPrevButtonsComponent,
            PagerNextButtonsComponent,
            PagerNumericButtonsComponent,
            PagerInputComponent,
            PagerInfoComponent,
            PagerPageSizesComponent,
            PagerTemplateDirective
        ];
    }
};
PagerModule = __decorate([
    NgModule({
        declarations: [INTERNAL_COMPONENTS],
        exports: [INTERNAL_COMPONENTS],
        imports: [...importedModules],
        providers: [providers]
    })
], PagerModule);

/**
 * Generated bundle index. Do not edit.
 */

export { LocalizedMessagesDirective, Messages, PagerContextService, PagerElementComponent, PreventableEvent, PageSizeChangeEvent, CustomMessagesComponent, PagerPrevButtonsComponent, PagerPageSizesComponent, PagerTemplateDirective, PagerNumericButtonsComponent, PagerNextButtonsComponent, PagerInputComponent, PagerInfoComponent, PagerComponent, PagerModule };
